// src/index.tsx

// This is the main entry point for the site. It is responsible for rendering the site and setting up the theme, context, and authentication systems.

// React Imports
import React from "react";
import ReactDOM from "react-dom/client";
// Material UI Imports
import { ThemeProvider, CssBaseline } from "@mui/material";
// Theme Imports
import theme from "theme/theme";
// Authentication Imports
import Authenticate from "helpers/Authenticate";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
// CSS Imports
import "./index.css";
// Datadog Imports
import { datadogRum } from "@datadog/browser-rum";

Amplify.configure(config);

datadogRum.init({
    applicationId: "dcc4d5d7-cb43-4a47-ae23-7b89222eb2ed",
    clientToken: "pub5d98f056bf26a990739ef9ce5aeb1272",
    site: "datadoghq.com",
    service: "servquest-web",
    env: "dev",
    version: "1.0.0", // Specify a version number to identify the deployed version of your application in Datadog.
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording(); // Have datadog record sessions for playback.

// Our types for the site data
export interface Props {
    name: String;
    description: String;
    subdomain: String;
    customDomain: String;
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <Authenticator.Provider>
                    <Authenticate />
                </Authenticator.Provider>
            </CssBaseline>
        </ThemeProvider>
    </React.StrictMode>,
);
