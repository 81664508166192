// React Imports
import React, { ReactNode, useEffect, useState } from "react";
// FeatureFlag Imports
import { FeatureFlagContext } from "./FeatureFlagContext";
import { FeatureFlagInterface } from "./FeatureFlagInterface";
// Setting Imports
import { GetSetting } from "../services/GetInvoiceData";

interface FeatureFlagProviderProps {
    children: ReactNode;
    tenantId?: string; // TODO: This might be required, need to verify the object structure when servers are stable. Remove the '?' if required.
    // tenantId is not used at the moment. Need to do more testing to see how it will be used.
}

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({ children, tenantId }) => {
    // Initialize the feature flag state within the provider. This state is modified asynchronously when the feature flags are fetched from the external source.
    // Also serves as a fallback in case the feature flags are not fetched in time.
    const [featureFlags, setFeatureFlags] = useState<FeatureFlagInterface>({
        enableTestFeatureFlag: false,
        enableDataDogMonitoring: false,
    });

    useEffect(() => {
        // This function fetches the feature flag values from the external source.
        // Initially set with default values, but will be updated dynamically based on the fetched data once implemented.
        const fetchFeatureFlags = async () => {
            // TODO: This will be an API call to an endpoint that provides the feature flag values.
            if (!tenantId) {
                console.log("No tenantId provided.");
                return; // TODO: Handle the error case when no tenantId is provided.
            }

            try {
                const enableTestFeatureFlag = await GetSetting("enableTestFeatureFlag", "false");
                const enableDataDogMonitoring = await GetSetting(
                    "enableDataDogMonitoring",
                    "false",
                );

                const fetchedFeatureFlags: FeatureFlagInterface = {
                    enableTestFeatureFlag: enableTestFeatureFlag.toLowerCase() === "true",
                    enableDataDogMonitoring: enableDataDogMonitoring.toLowerCase() === "true",
                };
                setFeatureFlags(fetchedFeatureFlags);
            } catch (error) {
                console.error("Error fetching feature flags: ", error);
                setFeatureFlags({
                    enableTestFeatureFlag: false,
                    enableDataDogMonitoring: false,
                });
            }
        };

        fetchFeatureFlags();
    }, []);

    return (
        <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
    );
};
