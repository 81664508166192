// src/featureflag/FeatureFlagContext.tsx

// React Imports
import React, { createContext, useContext, useState, useEffect } from "react";
// FeatureFlag Imports
import { FeatureFlagInterface } from "./FeatureFlagInterface";

// Required: Default Value for FeatureFlags
// This defines the initial shape and default values for the feature flags when the context is first created.
const defaultFeatureFlag: FeatureFlagInterface = {
    enableTestFeatureFlag: false,
    enableDataDogMonitoring: false,
};

// FeatureFlagContext Creation
const FeatureFlagContext = createContext<FeatureFlagInterface>(defaultFeatureFlag);

export const useFeatureFlag = () => {
    return useContext(FeatureFlagContext);
};

export { FeatureFlagContext };
